
#theme-import-bg {
    overflow-y: auto;
    overflow-x: hidden;
  }
  #theme-import-bg.modal-open {
    overflow: hidden;
  }
  #theme-import-bg #accordionMigration {
    height: calc(100vh - 18.5em);
    background: $white;
    overflow: auto;
    border: 1px solid $gray-300;
  }
  #theme-import-bg .import_wizardsteps {
    width: 100%;
    padding: 15px 30px;
    border-top: 1px solid $gray-300;
    box-sizing: border-box;
    min-height: 65px;
    position: fixed;
    bottom: 33px;
    left: 0;
    background-color: $white;
    z-index: 3;
  }
  #theme-import-bg .main-content {
    background-color: $gray-300;
  }
  #theme-import-bg #progressbar li {
    list-style-type: none;
    color: $gray-700;
    text-transform: uppercase;
    font-size: 9px;
    width: 13.666667%;
    position: relative;
    display: inline-block;
    float: none;
  }
  #theme-import-bg #upload-file-wrapper {
    position: relative;
    border: 2px dashed $gray-300;
    box-shadow: rgba(0, 0, 0, 0.380392) 0 0 25px -8px;
    height: 100%;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 6px;
    transition: width 0.5s ease;
  }
  #theme-import-bg .import_upload_btn.btn {
    color: $warning;
    text-decoration: none;
  }
  #theme-import-bg .import_upload_btn.btn:hover {
    color: $white;
  }
  #theme-import-bg #upload-file-wrapper p.desc {
    font-size: 0.9rem;
    color: $gray-700;
  }
  #theme-import-bg .migNotifyEmailDesc {
    font-size: 0.7rem;
    text-align: left;
  }
  #theme-import-bg .migNotifyEmailDesc h3.bold {
    color: $white;
  }
  #theme-import-bg .table-bordered-custom_design {
    border: 1px solid $gray-300;
  }
  #theme-import-bg .back_btn {
    margin-left: 10px;
  }
  #theme-import-bg #action_btns input[type=checkbox]::before {
    position: absolute;
    content: "";
    display: block;
    top: 0.1em;
    left: 0.4em;
  }
  #theme-import-bg .collapsed .rotate-icon {
    transform: rotate(-90deg);
  }
  #theme-import-bg .rotate-icon {
    transform: rotate(0);
  }
  #theme-import-bg .cnt-label {
    box-sizing: border-box;
    border-radius: 7.5px;
    background-color: $gray-200;
    font-size: 0.9rem;
    text-align: center;
    color: $white;
    line-height: 16px;
    padding: 2px 5px;
  }
  #theme-import-bg #unmapped-files .list-group .list-group-item {
    border: 0;
    cursor: -webkit-grab;
    border-radius: 0;
  }
  #theme-import-bg #unmapped-files .list-group .list-group-item:hover {
    background-color: $gray-100;
    position: relative;
  }
  #theme-import-bg #mapped-files .list-group .list-group-item {
    border: 0;
    cursor: pointer;
    border-radius: 0;
  }
  #theme-import-bg #mapped-files .list-group .list-group-item .mig-dragicon {
    display: none !important;
  }
  #theme-import-bg #mapped-files .list-group .list-group-item:hover {
    background-color: $gray-100;
    position: relative;
  }
  #theme-import-bg .acc-card {
    border-bottom: 1px solid $gray-300;
    border-radius: 0;
  }
  #theme-import-bg .acc-card .card-body {
    background-color: $gray-100;
    padding: 0;
    overflow: auto;
    width: 100%;
    height: 200px !important;
  }
  #theme-import-bg span.fname {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 0 0 7px;
    color: $gray-700;
  }
  #theme-import-bg .expandarrow {
    background-position: -15px -24px;
    width: 11px;
    height: 7px;
  }
  #theme-import-bg .mig-dragicon {
    width: 5px;
    height: 12px;
    margin-right: 5px;
  }
  #theme-import-bg .mig-dragicon-pos {
    background-position: -99px -40px;
  }
  #theme-import-bg .highcharts-figure {
    min-width: 200px;
  }
  #theme-import-bg .highcharts-figure .chart-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  #theme-import-bg .import_card .highcharts-figure .chart-container {
    width: 260px;
    height: 260px;
    margin: 0 auto;
  }
  #theme-import-bg #graphForMappedFeilds.chart-container {
    width: 40px;
    height: 40px;
  }
  #theme-import-bg .highcharts-figure, #theme-import-bg .highcharts-data-table table {
    width: 100%;
    margin: 0 auto;
  }
  #theme-import-bg .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid $gray-300;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }
  #theme-import-bg .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: $gray-700;
  }
  #theme-import-bg .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }
  #theme-import-bg .highcharts-data-table td, #theme-import-bg .highcharts-data-table th, #theme-import-bg .highcharts-data-table caption {
    padding: 0.5em;
  }
  #theme-import-bg .highcharts-data-table thead tr, #theme-import-bg .highcharts-data-table tr:nth-child(even) {
    background: $gray-100;
  }
  #theme-import-bg .highcharts-data-table tr:hover {
    background: $gray-100;
  }
  #theme-import-bg .mapping-legends .mig_legend_circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-sizing: border-box;
  }
  #theme-import-bg .mapping-legends .bg_mapped {
    background-color: #19bc7d;
  }
  #theme-import-bg .mapping-legends .bg_unmapped {
    background-color: $gray-300;
  }
  #theme-import-bg .nofiles .nofiles-txt {
    color: $gray-700;
  }
  #theme-import-bg .import_m_box {
    border: 1px solid $gray-300;
    transition: all 0.3s ease;
    border-radius: 0;
    box-sizing: border-box;
    background-color: $white;
    box-shadow: 0 2px 7px -1px rgba(180, 180, 180, 0.4);
    outline: none;
    height: auto;
  }
  #theme-import-bg .import_m_box .import_m_box_icon {
    height: 96px;
    width: 100%;
    border-bottom: 1px solid $gray-300;
    background-color: $white;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #theme-import-bg .import_m_box .import_m_box_icon svg {
    transition: 0.3s ease;
    opacity: 0.7;
    font-size: 1.4rem;
    color: $gray-700;
  }
  #theme-import-bg .import_m_box .import_m_box_icon:hover svg {
    font-size: 2rem;
  }
  #theme-import-bg .import_m_box .import_m_box_txt {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    flex-flow: wrap;
    min-height: 58px;
  }
  #theme-import-bg .import_m_box .clearB {
    clear: both;
  }
  #theme-import-bg .import_m_box .import_m_box_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-700;
    width: 80%;
  }
  #theme-import-bg .import_m_box .import_m_box_file {
    color: $gray-700;
    cursor: pointer;
  }
  #theme-import-bg .import_m_box .import_m_box_file .mapped-files {
    font-size: 0.9rem;
  }
  #theme-import-bg .dataTables_wrapper table td {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    text-align: left;
    padding-left: 5px;
  }
  #theme-import-bg .dataTables_wrapper table.dataTable thead th {
    text-align: left !important;
    padding-left: 0.5rem;
  }
  #theme-import-bg .dataTables_wrapper table.dataTable thead th[class*=sorting]:not(.sorting_disabled) {
    font-weight: 600;
    min-width: 200px !important;
  }
  #theme-import-bg .import_custom_table .table-fixed {
    table-layout: fixed;
  }
  #theme-import-bg .import_custom_table .table-fixed thead {
    width: 97%;
    border-left: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
  }
  #theme-import-bg .import_custom_table .table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
    border: 1px solid $gray-300;
  }
  #theme-import-bg .import_custom_table th {
    empty-cells: show;
    font-size: 0.9rem;
    color: $gray-700;
    text-align: left;
    font-weight: normal;
    vertical-align: bottom;
    border-bottom: 4px double $gray-300;
    background: transparent;
    padding: 5px 0;
    border-top: 1px solid $gray-300;
  }
  #theme-import-bg .import_custom_table .table-fixed tbody td, #theme-import-bg .import_custom_table .table-fixed thead > tr > th {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  #theme-import-bg .import_custom_table tr:hover {
    background-color: $gray-100;
  }
  #theme-import-bg .module_box_active .import_m_box_icon {
    background-color: $white;
  }
  #theme-import-bg .boxHighlight {
    box-shadow: 6px 6px 49px -10px rgba(0, 0, 0, 0.28);
  }
  #theme-import-bg .c-col-2 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
    z-index: 2;
    padding: 2em 0em 0em 2em;
  }
  #theme-import-bg .dragFreeze {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: $gray-500;
    z-index: 1;
  }
  #theme-import-bg .import_m_box.drop_border {
    border: 2px dashed $gray-300 !important;
  }
  @media (max-width: 600px) {
    #theme-import-bg .highcharts-figure, #theme-import-bg .highcharts-data-table table {
      width: 100%;
    }
    #theme-import-bg .highcharts-figure .chart-container {
      width: 100%;
      float: none;
      margin: 0 auto;
    }
  }
  #theme-import-bg g.highcharts-axis-labels.highcharts-yaxis-labels {
    display: none;
  }
  #theme-import-bg #field-mapping-tabs li > a.active {
    color: $warning;
  }
  #theme-import-bg .import_card {
    border: 1px solid $gray-300;
    box-sizing: border-box;
    background-color: $white;
    padding: 15px;
    box-shadow: 0 -1px 9px rgba(0, 0, 0, 0.1);
  }
  #theme-import-bg .bg_mapped_review {
    background-color: #8c79de;
  }
  .hide {
    display: none;
}

#progressbar li.active:before, #progressbar li.active:after {
  border-color: $warning;
  color: $warning;
}

.bg-white-c {
  background: #fff !important;
}
.upload_file_card {
  background-color: $white;
}
.import_card.bg-white-c, .import_modChart.bg-white-c {
  background: $white !important;
  rect.highcharts-background {
    fill: $white !important;
  }
  .highcharts-label {
    span {
      color: $dark !important;
    }
  }
}
a.file_unmapping {
  margin-left: 10px;
}
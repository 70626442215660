.navbar-expand-lg.navbar-standard .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after, .navbar-expand-lg.navbar-top .navbar-nav:not(.navbar-nav-icons) .dropdown-menu:after,
.navbar .dropdown-menu:after
{
    display: none !important;
}
.dropdown-menu {
    border: 1px solid $gray-300;
}

.dropdown-menu:not(.dropdown-menu-right) {
    left: 0 !important;
}

li[data-sfuniqueid="navbar__additional_modules"] {
    .dropdown-menu {
        .rounded-soft {
            max-height: 50vh;
            overflow-y: auto;
        }
    }
}
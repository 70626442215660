.tour-list__item {
    width: 100%;
    height: auto;
    display: block;
    text-align: center;
    padding: 20px 10px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    cursor: pointer;
}
.popover[class*='tour-tour-guide'] {
    max-width: 320px;
    table {
        td {
            padding: 0 5px;
        }
    }
}
.guided-tour-panel-body {
    overflow: auto;
    height: calc(100vh - 9.5rem);
    background: $gray-100;
}
.sf-tour-progress {
    border-radius: 4px 4px 0 0;
}
.sf-tour-footer {
    position: relative;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
    text-align: center;
    background: $gray-100;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sf-tour-quickstart-btn {
    position: fixed;
    bottom: 50px;
    right: 10px;
    transition: margin 220ms ease 0s;
    margin-bottom: 0px;
    cursor: pointer;
    border-radius: 24px;
    font-size: 14px;
    border: none;
    outline: none;
    white-space: nowrap;
    padding: 10px 18px;
    box-shadow: #091e4240 0px 8px 16px -4px, #091e4240 0px 0px 1px;
    transition: all 0.8s ease-in-out 0s;
    display: none;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
}

.opacity-100 {
    opacity: 100 !important;
}

.popover[class*="tour-"].orphan {
    left: 50% !important;
    top: 50% !important;
    position: fixed !important;
    transform: translate(-50%, -50%) !important;
}